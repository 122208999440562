import AppChrome from '../components/AppChrome'
import Hero from '../components/Hero'
import PageContent from '../components/pageContent/PageContent'

export { getServerSideProps } from '../graphql/homepage'

function Homepage( { entry: homepage } ) {
  const heroSection = homepage.heroSection[ 0 ]
  
  return (
    <AppChrome
      metaTags={ {
        url: `https://${ process.env.NEXT_PUBLIC_APP_HOSTNAME }`,
        title: `${ homepage.title } — Finance Uncovered`,
        image: heroSection.heroImage[ 0 ].url,
      } }
    >
      <Hero
        title={ heroSection.heroTitle }
        intro={ heroSection.heroIntro }
        image={ heroSection.heroImage[ 0 ] }
        link={ heroSection.heroLink?.link }
      />
      <PageContent pageContent={ homepage.pageContent } />
    </AppChrome>
  )
}

export default Homepage
